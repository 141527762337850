@import 'scss/variables';
@import 'scss/mixins';

.errorContainer {
  margin: auto;
}

.fullScreenHeight {
  height: 100vh;
}

.errorImg {
  height: auto;
  max-width: 330px;
  width: 100%;

  @include media-breakpoint-only(xs) {
    width: 70%;
  }
}

.errorTitle {
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
}

.errorSubtitle {
  color: $gray-800;
  text-align: center;
  max-width: 400px;
}
